import React from "react";
import { navBarItems } from "./NavigationBar";
import resources from "../resources"
const instagramBtn = resources.icons.socialMedia.instagramBtn
const thingiverseBtn = resources.icons.thingiverseBtn
const youtubeBtn = resources.icons.socialMedia.youtubeBtn

const webLinks = navBarItems;
const socialMediaLinks = [
  { label: "Thingiverse", lnk: "https://www.thingiverse.com/thebinaryartisan/designs", icon: thingiverseBtn },
  { label: "Youtube", lnk: "https://www.youtube.com/channel/UCCHVf7HeGUcX6d94YPUFhfg", icon: youtubeBtn },
  { label: "Instagram", lnk: "https://www.instagram.com/thebinaryartisan/?igshid=MzRlODBiNWFlZA%3D%3D", icon: instagramBtn },
];

const OurWorkLinks = [
  { label: "Shearman Roofing", lnk: "https://shearmanroofingco.com" },
  // { label: "Portfolio", lnk: "" },
];

const SocialMediaLinks = () => {
  return (
    <div className="w-full text-center">
      <div className="inline-block">
        {socialMediaLinks.map(({ label, lnk, icon }, i) => (
          <div
            key={i}
            className="inline-block tablet:mx-5 laptop:mx-20 shadow-sm rounded-full"
          >
            <a
              href={lnk}
              target="_blank"
              rel="noopener noreferrer"
              className="text-center justify-center"
            >
              <img
                src={icon}
                alt={label}
                className="w-14 m-2 h-auto tablet:w-20 tablet:h-auto laptop:w-24 bg-background1"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const WebLinks = () => {
  return (
    <div className=" block">
      <p className="w-full text-md underline-offset-1 underline text-txtColor1">Quick Links</p>
      {webLinks.map(({ label, lnk }, i) => {
        return (
          <div key={i} className="">
            <a href={lnk} className="mx-5 text-sm tablet:text-md text-txtColor2 hover:bg-text-gray-800 w-full">
              {label}
            </a>
          </div>
        );
      })}
    </div>
  );
};

const OurWork = () => {
  return (
    <div className="block">
      <p className="w-full text-md underline-offset-1 underline text-txtColor1">Our Work</p>
      {OurWorkLinks.map(({ label, lnk }, i) => (
        <div key={i} className="">
          <a href={lnk} className="mx-5 text-sm tablet:text-md text-txtColor2 hover:bg-text-gray-800 w-full">
            {label}
          </a>
        </div>
      ))}
    </div>
  );
};

const Footer = () => {
  return (
    <div className="">
      <div className="z-50 shadow-lg shadow-accent1 p-3 my-2 justify-center"></div>
      <div className="bg-background1 mobile:block tablet:flex flex-col mb-6" >
        <div className="mb-6">{SocialMediaLinks()}</div>
        <div className="block justify-center text-center tablet:text-left tablet:flex ">
          <div className="mx-5">{OurWork()}</div>
          <div className="mx-5">{WebLinks()}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
