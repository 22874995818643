import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import netlifyIdentity from "../identity";
import { handleLogin } from "./Login";

const Documentation = () => {
  netlifyIdentity.init({})
  const currentUser = netlifyIdentity.currentUser()
  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      {currentUser!==null&&<p>Contacts, Documentation, How tos, tutorials, build-information, etc. </p>}
      {currentUser===null&&<p onClick={handleLogin}>Login to View Documentation Page</p>}
      {Footer()}
    </div>
  );
};

export default Documentation;
