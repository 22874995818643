import React, { } from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import { postDocument, getCollection } from "../API"; // replace with the file where the functions are declared

const Page1 = () => {
  
  const handleGetCollection = async () => {
    try {
      const response = await getCollection("people", "Directory");
      console.log(response.data);
    } catch (error) {
      console.log("An error occurred: " + error.message);
    }
  };

  const handlePostDocument = async () => {
    const document = {
      field1: "value3",
      field2: "value4"
      // ... other fields for the document ...
    };
    try {
      const response = await postDocument(document, "userCompanies", "Users");
      console.log(response.data);
    } catch (error) {
      console.log("An error occurred: " + error.message);
    }
  };

  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      <button onClick={handleGetCollection}>Get Collection</button>
      <button onClick={handlePostDocument}>Post Document</button>
      <Footer/>
    </div>
  );
};

export default Page1;
