import React from "react";
import resources from "../resources";
const BinaryArtisanLogo = resources.images.companyLogo;

const MobileIcon = () => {
  return (
    <div className="block tablet:hidden text-center my-5">
      <img src={BinaryArtisanLogo} alt="" className="inline-block w-7/12 rounded-full shadow-xl" onClick={()=>window.location.href='/'}></img>
    </div>
  );
};

export default MobileIcon;
