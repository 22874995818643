import axios from "axios";
import queryString from 'query-string';

export const postDocument = (document:any, collection:string, database:string) => {
  const params = queryString.stringify({ collection, database });
  return axios.post(`/.netlify/functions/saveRecord?${params}`, JSON.stringify(document));
};

export const getCollection = (collection: string, database: string) => {
  const params = queryString.stringify({ collection, database });
  return axios.get(`/.netlify/functions/readCollection?${params}`);
};
