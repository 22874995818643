import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const MyEditor = (editorTitle=undefined) => {
  const [text, setText] = useState('');

  const handleChange = (value:any) => {
    setText(value);
  };

  return (
    <div className="w-10/12 mx-auto mt-8 bg-gray-100 border border-gray-300 rounded p-4">
      {editorTitle&&<p className='text-lg'>{editorTitle}</p>}
      <ReactQuill value={text} onChange={handleChange} className='my-5 mx-auto w-10/12'/>
    </div>
  );
};

export default MyEditor;
