import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import MyEditor from "../components/MyEditor";

const Page2 = () => {
  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      {MyEditor('RFC Description')}
      {MyEditor('RFC Reply')}
      {Footer()}
    </div>
  );
};

export default Page2;
