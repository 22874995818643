import netlifyIdentity from 'netlify-identity-widget'

export function initNetlifyIdentity() {
  netlifyIdentity.init({});
}
export const phCurrentUser = {
  api: {
      "apiURL": "/.netlify/identity",
      "_sameOrigin": true,
      "defaultHeaders": {
          "X-Use-Cookie": "1"
      }
  },
  url: "/.netlify/identity",
  token: {
      "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODg5Nzc3NTYsInN1YiI6ImRiMGRlYTgzLTFhNmItNGNhOC05NTNjLTA2YmQ5OWQxNjM4ZSIsImVtYWlsIjoidGhlLmJpbmFyeS5hcnRpc2FuQGdtYWlsLmNvbSIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6Imdvb2dsZSJ9LCJ1c2VyX21ldGFkYXRhIjp7ImF2YXRhcl91cmwiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRmNzRYVzJWbGVxemFFa2NIaGNXSmFHTWk4OVYtZ2pDeUNnZEV1elZpbVA9czk2LWMiLCJmdWxsX25hbWUiOiJCaW5hcnkgQXJ0aXNhbiJ9fQ.PaUwprEAN3F7_r9f6v_9xR8YHU6WxEeZd_RQaqvTJ_Q",
      "expires_in": "3600",
      "refresh_token": "k6ppMgVyjRS2EyEDYSHZsw",
      "token_type": "bearer",
      "expires_at": 1688977756000
  },
  id: "db0dea83-1a6b-4ca8-953c-06bd99d1638e",
  aud: "",
  role: "",
  email: "exampleUser@gmail.com",
  confirmed_at: "2023-07-10T06:35:16Z",
  app_metadata: {
      "provider": "google"
  },
  user_metadata: {
      "avatar_url": "https://lh3.googleusercontent.com/a/AAcHTtf74XW2VleqzaEkcHhcWJaGMi89V-gjCyCgdEuzVimP=s96-c",
      "full_name": "Binary Artisan"
  },
  created_at: "2023-07-10T06:35:16Z",
  updated_at: "2023-07-10T06:35:16Z",
  _fromStorage: true
  };

export default netlifyIdentity;
//
// Example netlifyIdentity.currentUser
//
// {
// "api": {
//     "apiURL": "/.netlify/identity",
//     "_sameOrigin": true,
//     "defaultHeaders": {
//         "X-Use-Cookie": "1"
//     }
// },
// "url": "/.netlify/identity",
// "token": {
//     "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODg5Nzc3NTYsInN1YiI6ImRiMGRlYTgzLTFhNmItNGNhOC05NTNjLTA2YmQ5OWQxNjM4ZSIsImVtYWlsIjoidGhlLmJpbmFyeS5hcnRpc2FuQGdtYWlsLmNvbSIsImFwcF9tZXRhZGF0YSI6eyJwcm92aWRlciI6Imdvb2dsZSJ9LCJ1c2VyX21ldGFkYXRhIjp7ImF2YXRhcl91cmwiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRmNzRYVzJWbGVxemFFa2NIaGNXSmFHTWk4OVYtZ2pDeUNnZEV1elZpbVA9czk2LWMiLCJmdWxsX25hbWUiOiJCaW5hcnkgQXJ0aXNhbiJ9fQ.PaUwprEAN3F7_r9f6v_9xR8YHU6WxEeZd_RQaqvTJ_Q",
//     "expires_in": "3600",
//     "refresh_token": "k6ppMgVyjRS2EyEDYSHZsw",
//     "token_type": "bearer",
//     "expires_at": 1688977756000
// },
// "id": "db0dea83-1a6b-4ca8-953c-06bd99d1638e",
// "aud": "",
// "role": "",
// "email": "the.binary.artisan@gmail.com",
// "confirmed_at": "2023-07-10T06:35:16Z",
// "app_metadata": {
//     "provider": "google"
// },
// "user_metadata": {
//     "avatar_url": "https://lh3.googleusercontent.com/a/AAcHTtf74XW2VleqzaEkcHhcWJaGMi89V-gjCyCgdEuzVimP=s96-c",
//     "full_name": "Binary Artisan"
// },
// "created_at": "2023-07-10T06:35:16Z",
// "updated_at": "2023-07-10T06:35:16Z",
// "_fromStorage": true
// }