import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import netlifyIdentity from "../identity";
import { handleLogin } from "./Login";

const CommunicationPage = () => {
  netlifyIdentity.init({})
  const currentUser = netlifyIdentity.currentUser()
  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      {currentUser!==null&&<p>Request for Comment, schedule emails, </p>}
      {currentUser===null&&<p onClick={handleLogin}>Login to View Communication Page</p>}
      {Footer()}
    </div>
  );
};

export default CommunicationPage;
