import React from "react";
import MobileNavbar from "../components/MobileNavBar";
import NavBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MobileIcon from "../components/MobileIcon";
import netlifyIdentity from "../identity";
import { handleLogin } from "./Login";

const ResourcesPage = () => {
  netlifyIdentity.init({})
  const currentUser = netlifyIdentity.currentUser()
  return (
    <div>
      {MobileNavbar()}
      {NavBar()}
      {MobileIcon()}
      {currentUser!==null&&<p>Billings, Time Tracking, Expense forms, Generate Invoice</p>}
      {currentUser===null&&<p onClick={handleLogin}>Login to View Resources Page</p>}
      {Footer()}
    </div>
  );
};

export default ResourcesPage;
