import hamburgerBtn from "./resources/icons/hamburger-svgrepo-com.svg"
import youtubeBtn from "./resources/icons/youtube-168-svgrepo-com.svg"
import linkedinBtn from "./resources/icons/linkedin.svg"
import instagramBtn from "./resources/icons/instagram-svgrepo-com.svg"
import reachOut from "./resources/icons/contact-video-call-svgrepo-com.svg"
import consult from "./resources/icons/conversation-confer-consult-speak-svgrepo-com.svg"
import planning from "./resources/icons/planning-svgrepo-com.svg"
import develop from "./resources/icons/industry-worker-with-cap-protection-and-a-laptop-svgrepo-com.svg"
import maintain from "./resources/icons/computer-setting-outline-interface-symbol-in-a-circle-svgrepo-com.svg"
import thingiverseBtn from "./resources/icons/brand-thingiverse-svgrepo-com.svg"
import contactUsBtn from "./resources/icons/contact-us-svgrepo-com.svg"
import batman from "./resources/images/batman.webp"
import blob from "./resources/images/blob.webp"
import napoleon from "./resources/images/napoleon.jpg"
import planet from "./resources/images/planet.jpg"
import flash from "./resources/images/theFlash.jpg"
import companyLogo from "./resources/images/BINARY_ARTISAN-removebg.png"
import dHilleke from './resources/images/dHilleke.jpeg'
import sSliman from './resources/images/sam_sliman.jpg'
import upload from "./resources/icons/upload-square-svgrepo-com.svg"
import next from "./resources/icons/next4-svgrepo-com.svg"
import back from "./resources/icons/back2-svgrepo-com.svg"
const resources = {
  icons:{
    hamburgerBtn,
    reachOut,
    consult,
    planning,
    develop,
    maintain,
    thingiverseBtn,
    contactUsBtn,
    upload,
    next, 
    back,

    socialMedia: {
      youtubeBtn,
      linkedinBtn,
      instagramBtn,
    }
  },
  images:{
    batman,
    blob,
    napoleon,
    planet,
    flash, 
    companyLogo,
    dHilleke,
    sSliman,
  },
}



export default resources