// import logo from './logo.svg';
// testing
import Page1 from './pages/pg1';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Page2 from './pages/pg2';
import LoginPage from './pages/Login';
import CommunicationPage from './pages/Communication';
import FinancialsPage from './pages/Financials';
import ClientProfilesPage from './pages/ClientProfiles';
import Documentation from './pages/Documentation';

function App() {
  document.title = "BA Team Portal";

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/" element={LoginPage()}>
          </Route>
          <Route path="/pg1" element={Page1()}>
          </Route>
          <Route path="/pg2" element={Page2()}>
          </Route>
          <Route path="/login" element={LoginPage()}>
          </Route>
          <Route path="/communication" element={CommunicationPage()}>
          </Route>
          <Route path="/financials" element={FinancialsPage()}>
          </Route>
          <Route path="/clientProfiles" element={ClientProfilesPage()}>
          </Route>
          <Route path="/resources" element={Documentation()}>
          </Route>
          {/* <Route path="/" element={()}>
          </Route>
          <Route path="/" element={()}>
          </Route> */}
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
